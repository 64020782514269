<template>
    <div class="addProperty">
        <!--        导航-->
        <rxNavBar :title="flagTitle"></rxNavBar>
        <!--上传图片loading-->
        <loading v-if="loadingFlag"></loading>
        <!--        电表交割-->
        <div class="waterMeterTitle">
            <span class="redText">电表交割</span>
        </div>
        <!--       电表交割信息 -->
        <div class="waterMeterDeliveryInfo">
            <!--            联合收费处-->
            <div class="cashier">
<!--                <div :class="dotOne3==''?grayDot:redDot"></div>-->
                <span  class="cashierTextg" @click="isShowElectric=!isShowElectric">
                    {{type3.dictionaryTitle}}
                </span>
                <img src="../../../assets/images/triangle.png" :class="!isShowElectric?'downImage':'upImage'">
                <span class="rule">|</span>
                <input class="inputNum" v-model="dotOne3" type="text" placeholder="输入电表号"  >
            </div>
            <van-popup v-model="isShowElectric" position="bottom">
                <van-picker show-toolbar value-key="dictionaryTitle" :columns="list3"
                            @cancel="isShowElectric = false" @confirm="selectElecType" />
            </van-popup>
            <!--            分割线1-->
            <div class="ruleOne"></div>
            <!--            电表底数-->
            <div class="cashier">
<!--                <div :class="dotTwo3==''?grayDot:redDot"></div>-->
                <span class="cashierText">电表底数</span>
                <span class="rule">|</span>
                <input class="inputNum" v-model="dotTwo3" type="number" placeholder="输入电表底数" >
            </div>
            <!--            分割线2-->
            <div class="ruleOne"></div>
            <!--            上传图片-->
            <van-uploader v-model="waterMeterFileList3"
                          class="upLoadImg"
                          :max-count="1"
                          accept="image/*"
                          preview-size="93px"
                          capture="camera"
                          :after-read="afterReadTest"/>
            <div class="upLoadText">
                <span>*请务必上传</span>
            </div>
            <!--            分割线5-->
            <div class="ruleOne"></div>
            <!--                备注-->
            <textarea class="textareaRemarks" v-model="wattMeterRemarks" placeholder="描述"></textarea>
            <div></div>
        </div>
        <!--        水表交割-->
        <div class="waterMeterTitle">
            <span class="redText">水表交割</span>
        </div>
        <!--       水表交割信息 -->
        <div class="waterMeterDeliveryInfo">
            <!--            联合收费处-->
            <div class="cashier">
<!--                <div :class="dotOne==''?grayDot:redDot"></div>-->
                <span  class="cashierTextg11" @click="isShowWater=!isShowWater">
                    {{type1.dictionaryTitle}}
                </span>
                <img src="../../../assets/images/triangle.png" :class="!isShowWater?'downImage':'upImage'">
                <span class="rule">|</span>
                <input class="inputNum" v-model="dotOne" type="text" placeholder="输入水表号"  >
            </div>
            <van-popup v-model="isShowWater" position="bottom">
                <van-picker show-toolbar value-key="dictionaryTitle" :columns="list1"
                            @cancel="isShowWater = false" @confirm="selectWaterType" />
            </van-popup>
            <!--            分割线1-->
            <div class="ruleOne"></div>
            <!--            水表底数-->
            <div class="cashier">
<!--                <div :class="dotTwo==''?grayDot:redDot"></div>-->
                <span class="cashierText">水表底数</span>
                <span class="rule">|</span>
                <input class="inputNum" v-model="dotTwo" type="number" placeholder="输入水表底数" >
            </div>
            <!--            分割线2-->
            <div class="ruleOne"></div>
            <!--            上传图片-->
            <van-uploader v-model="waterMeterFileList"
                          class="upLoadImg"
                          :max-count="1"
                          accept="image/*"
                          preview-size="93px"
                          capture="camera"
                          :after-read="afterReadTest"/>
            <div class="upLoadText">
                <span>*请务必上传</span>
            </div>
            <!--            分割线5-->
            <div class="ruleOne"></div>
            <!--                备注-->
            <textarea class="textareaRemarks" v-model="waterMeterRemarks" placeholder="描述"></textarea>
            <div></div>
        </div>

        <!--        热水表交割-->
        <div class="waterMeterTitle" v-if="hotWaterMeterInfo">
            <span class="redText">热水表交割</span>
        </div>
        <!--       热水表交割信息 -->
        <div class="waterMeterDeliveryInfo" v-if="hotWaterMeterInfo">
            <!--            联合收费处-->
            <div class="cashier">
<!--                <div :class="dotOne==''?grayDot:redDot"></div>-->
                <span  class="cashierTextg11" @click="isShowHotWater=!isShowHotWater">
                    {{type2.dictionaryTitle}}
                </span>
                <img src="../../../assets/images/triangle.png" :class="!isShowHotWater?'downImage':'upImage'">
                <span class="rule">|</span>
                <input class="inputNum" v-model="dotOne2" type="text" placeholder="输入热水表号"  >
            </div>
            <van-popup v-model="isShowHotWater" position="bottom">
                <van-picker show-toolbar value-key="dictionaryTitle" :columns="list2"
                            @cancel="isShowHotWater = false" @confirm="selectHotWaterType" />
            </van-popup>
            <!--            分割线1-->
            <div class="ruleOne"></div>
            <!--            水表底数-->
            <div class="cashier">
<!--                <div :class="dotTwo==''?grayDot:redDot"></div>-->
                <span class="cashierText">热水表底数</span>
                <span class="rule">|</span>
                <input class="inputNum" v-model="dotTwo2" type="number" placeholder="输入热水表底数" >
            </div>
            <!--            分割线2-->
            <div class="ruleOne"></div>
            <!--            上传图片-->
            <van-uploader v-model="waterMeterFileList2"
                          class="upLoadImg"
                          :max-count="1"
                          accept="image/*"
                          preview-size="93px"
                          capture="camera"
                          :after-read="afterReadTest"/>
            <div class="upLoadText">
                <span>*请务必上传</span>
            </div>
            <!--            分割线5-->
            <div class="ruleOne"></div>
            <!--                备注-->
            <textarea class="textareaRemarks" v-model="hotWaterMeterRemarks" placeholder="描述"></textarea>
            <div></div>
        </div>

        <!--        燃气表交割-->
        <div class="waterMeterTitle">
            <span class="redText">燃气表交割</span>
        </div>
        <!--       燃气 表交割信息 -->
        <div class="waterMeterDeliveryInfo">
            <!--            联合收费处-->
            <div class="cashier">
<!--                <div :class="dotOne4==''?grayDot:redDot"></div>-->
                <span  class="cashierTextg" @click="isShowGas=!isShowGas">
                    {{type4.dictionaryTitle}}
                </span>
                <img src="../../../assets/images/triangle.png" :class="!isShowGas?'downImage':'upImage'">
                <span class="rule">|</span>
                <input class="inputNum" v-model="dotOne4" type="text" placeholder="输入燃气表号"  >
            </div>
            <van-popup v-model="isShowGas" position="bottom">
                <van-picker show-toolbar value-key="dictionaryTitle" :columns="list4"
                            @cancel="isShowGas = false" @confirm="selectGasType" />
            </van-popup>
            <!--            分割线1-->
            <div class="ruleOne"></div>
            <!--            燃气表底数-->
            <div class="cashier">
<!--                <div :class="dotTwo4==''?grayDot:redDot"></div>-->
                <span class="cashierText">燃气表底数</span>
                <span class="rule">|</span>
                <input class="inputNum" v-model="dotTwo4" type="number" placeholder="输入燃气表底数" >
            </div>
            <!--            分割线2-->
            <div class="ruleOne"></div>
            <!--            上传图片-->
            <van-uploader v-model="waterMeterFileList4"
                          class="upLoadImg"
                          :max-count="1"
                          accept="image/*"
                          preview-size="93px"
                          capture="camera"
                          :after-read="afterReadTest"/>
            <div class="upLoadText">
                <span>*请务必上传</span>
            </div>
            <!--            分割线5-->
            <div class="ruleOne"></div>
            <!--                备注-->
            <textarea class="textareaRemarks" v-model="gasMeterRemarks" placeholder="描述"></textarea>
            <div></div>
        </div>

        <div class="addPropertyDetail">
            <textarea class="textareaRemarks" placeholder="备注" v-model="description"></textarea>
        </div>

        <!--        检查项物品交割-->
        <div class="waterMeterTitle" v-if="mold=='1'&&roomSpecialProjectsList.length!=0">
            <span class="redText">房间检查项</span>
        </div>
        <div v-for="(item , i) in roomSpecialProjectsList" :key="item.name" class="specialProject" v-if="mold=='1'">
            <div class="houseDetail" v-if="roomSpecialProjectsList[i].canChange=='1' || ( roomSpecialProjectsList[i].canChange!='1' && roomSpecialProjectsList[i].isExistName==='有') || roomSpecialProjectsList[i].addOrUpdate=='1'">
                <!--            数量-->
                <div class="project">
                    <!--                    <div :class="houseConfigurationList[i].count==''?grayDot:redDot"></div>-->
                    <span class="projectStatus">名称</span>
                    <span class="rule">|</span>
<!--                    <div class="placeholderDiv">-->
<!--                    </div>-->
                    <div class="projectName">
                        <input type="text" class="count" v-model="roomSpecialProjectsList[i].dictionaryTitle" disabled="disabled">
                    </div>
                </div>
                <!--            分割线3-->
                <div class="ruleOne"></div>
                <div class="project" v-if="roomSpecialProjectsList[i].canChange!='1'">
                    <span class="projectStatus" >是否存在</span>
                    <span class="rule" >|</span>
                    <span class="projectStatusSelect">
                      {{roomSpecialProjectsList[i].isExistName}}
                    </span>
                </div>
              <div class="project" v-if="roomSpecialProjectsList[i].canChange=='1'">
                <div :class="roomSpecialProjectsList[i].isExistName==''?'grayDot':'redDot'" ></div>
                <span class="projectStatus" >是否存在</span>
                <span class="rule" >|</span>
                <span class="projectStatusSelect" @click="chooseExist(i,item)">
                            {{roomSpecialProjectsList[i].isExistName}}
                        </span>
                <img src="../../../assets/images/triangle.png" :class="!isShowExist?'downImage':'upImage'">
              </div>
                <van-popup v-model="isShowExist" position="bottom">
                    <van-picker show-toolbar value-key="label" :columns="existInfoList"
                                @cancel="isShowExist = false" @confirm="selectExistType" />
                </van-popup>
                <!--            分割线3-->
                <div class="ruleOne"></div>
                <div class="project" v-if="mold=='1'">
                    <!--                    <div :class="houseConfigurationList[i].name==''?grayDot:redDot" ></div>-->
                    <span class="projectStatus" >状态</span>
                    <span class="rule" >|</span>
                    <span class="projectStatusSelect" @click="showStatusList(i,item)">
                                    {{roomSpecialProjectsList[i].stateName}}
                                </span>
                    <img src="../../../assets/images/triangle.png" :class="!isShowStateTitle?'downImage':'upImage'">
                </div>
                <van-popup v-model="isShowStatusList" position="bottom">
                    <van-picker show-toolbar value-key="dictionaryTitle" :columns="statusInfoList"
                                @cancel="isShowStatusList = false" @confirm="selectStatusType" />
                </van-popup>
                <!--            分割线4-->
                <div class="ruleOne"></div>
                <!--            上传图片-->
                <div class="upLoadImg" >
                    <van-uploader v-model="roomSpecialProjectsList[i].houseFileList" multiple
                                  accept="image/*"
                                  preview-size="80px"
                                  :after-read="afterReadTest"
                                  @delete="deletePreview"
                                  style="padding-left: 0.25rem"/>
                </div>
                <div class="upLoadText">
                    <span >*请务必上传，{{roomSpecialProjectsList[i].dictionaryDescription}}</span>
                </div>
                <!--            分割线5-->
                <div class="ruleOne"></div>
                <!--                备注-->
                <textarea class="textareaRemarks" v-model="roomSpecialProjectsList[i].desc" placeholder="描述"></textarea>
                <div></div>
            </div>
        </div>
        <!--        公区检查项殊物品交割-->
        <div class="waterMeterTitle" v-if="mold=='1'&&houseType=='1'&&publicSpecialProjectList.length!=0">
            <span class="redText">公区检查项</span>
        </div>
        <div v-for="(item , i) in publicSpecialProjectList" :key="item.name" class="specialProject" v-if="mold=='1'&&houseType=='1'">
            <div class="houseDetail" v-if="publicSpecialProjectList[i].canChange=='1' || ( publicSpecialProjectList[i].canChange!='1' && publicSpecialProjectList[i].isExistName==='有') || publicSpecialProjectList[i].addOrUpdate=='1'">
            <!--            数量-->
            <div class="project">
              <!--                    <div :class="houseConfigurationList[i].count==''?grayDot:redDot"></div>-->
              <span class="projectStatus">名称</span>
              <span class="rule">|</span>
              <!--                    <div class="placeholderDiv">-->
              <!--                    </div>-->
              <div class="projectName">
                <input type="text" class="count" v-model="publicSpecialProjectList[i].dictionaryTitle" disabled="disabled">
              </div>
            </div>
            <!--            分割线3-->
            <div class="ruleOne"></div>
            <div class="project" v-if="publicSpecialProjectList[i].canChange!='1'">
              <span class="projectStatus" >是否存在</span>
              <span class="rule" >|</span>
              <span class="projectStatusSelect">
                  {{publicSpecialProjectList[i].isExistName}}
              </span>
            </div>
              <div class="project" v-if="publicSpecialProjectList[i].canChange=='1'">
                <div :class="publicSpecialProjectList[i].isExistName==''?grayDot:redDot" ></div>
                <span class="projectStatus" >是否存在</span>
                <span class="rule" >|</span>
                <span class="projectStatusSelect" @click="choosePublicExist(i,item)">
                              {{publicSpecialProjectList[i].isExistName}}
                          </span>
                <img src="../../../assets/images/triangle.png" :class="!isShowPublicExist?'downImage':'upImage'">
              </div>
            <van-popup v-model="isShowPublicExist" position="bottom">
              <van-picker show-toolbar value-key="label" :columns="existInfoList"
                          @cancel="isShowPublicExist = false" @confirm="selectPublicExistType" />
            </van-popup>
            <!--            分割线3-->
            <div class="ruleOne"></div>
            <div class="project" v-if="mold=='1'">
              <!--                    <div :class="houseConfigurationList[i].name==''?grayDot:redDot" ></div>-->
              <span class="projectStatus" >状态</span>
              <span class="rule" >|</span>
              <span class="projectStatusSelect" @click="showPublicStatusList(i,item)">
                                      {{publicSpecialProjectList[i].stateName}}
                                  </span>
              <img src="../../../assets/images/triangle.png" :class="!isShowStateTitle?'downImage':'upImage'">
            </div>
            <van-popup v-model="isShowPublicStatusList" position="bottom">
              <van-picker show-toolbar value-key="dictionaryTitle" :columns="statusInfoList"
                          @cancel="isShowPublicStatusList = false" @confirm="selectPublicStatusType" />
            </van-popup>
            <!--            分割线4-->
            <div class="ruleOne"></div>
            <!--            上传图片-->
            <div class="upLoadImg" >
              <van-uploader v-model="publicSpecialProjectList[i].houseFileList" multiple
                            accept="image/*"
                            preview-size="80px"
                            :after-read="afterReadTest"
                            @delete="deletePreview"
                            style="padding-left: 0.25rem"/>
            </div>
            <div class="upLoadText">
              <span >*请务必上传，{{publicSpecialProjectList[i].dictionaryDescription}}</span>
            </div>
            <!--            分割线5-->
            <div class="ruleOne"></div>
            <!--                备注-->
            <textarea class="textareaRemarks" v-model="publicSpecialProjectList[i].desc" placeholder="描述"></textarea>
            <div></div>
          </div>
        </div>


        <!--        房屋配置标题    引用水表交割标题字体样式-->
        <div class="waterMeterTitle">
            <span class="redText">物品交割</span>
        </div>
        <!--       房屋配置信息 -->
        <div class="houseConfiguration" v-for="(item , i) in houseConfigurationList" :key="i">
            <!--            红色取消按钮-->
            <div class="cancelBtn" @click="cancelBtn(i)" v-if="item.source!='1'">
                <div class="cancelImg"></div>
            </div>
            <!--            配置详情-->
            <div class="houseDetail">
                <div class="configuration" v-if="mold == '1'">
                    <span class="configurationName" >区域</span>
                    <span class="rule" >|</span>
                    <span class="configurationSelect"  @click="chooseRoom(i,item)">
                        {{houseConfigurationList[i].roomName}}
                    </span>
                    <img src="../../../assets/images/triangle.png" :class="!isShowRoom?'downImage':'upImage'">
                </div>
                <div class="ruleOne" v-if="mold == '1'"></div>
                <van-popup v-model="isShowRoom" position="bottom">
                    <van-picker show-toolbar value-key="roomName" :columns="roomTypeList"
                                @cancel="isShowRoom = false" @confirm="selectRoomType" />
                </van-popup>
                <!--             名称-->
                <div class="configuration">
<!--                    <div :class="houseConfigurationList[i].type==''?grayDot:redDot" ></div>-->
                    <span class="configurationName" >类型</span>
                    <span class="rule" >|</span>
                    <span class="configurationSelect" @click="chooseOne(i,item)">
                        {{houseConfigurationList[i].goodsAllocationClassName}}
                    </span>
                    <img src="../../../assets/images/triangle.png" :class="!isShowGoodsTitle?'downImage':'upImage'">
                </div>
                <van-popup v-model="isShowGoodsTitle" position="bottom">
                    <van-picker show-toolbar value-key="goodsAllocationClassName" :columns="typeInfoList"
                                @cancel="isShowGoodsTitle = false" @confirm="selectGoodsTitleType" />
                </van-popup>
                <!--            分割线3-->
                <div class="ruleOne"></div>
                <div class="configuration">
<!--                    <div :class="houseConfigurationList[i].name==''?grayDot:redDot" ></div>-->
                    <span class="configurationName" >名称</span>
                    <span class="rule" >|</span>
                    <span class="configurationSelect" @click="showGoodsName(i,item)">
                        {{houseConfigurationList[i].goodsName}}
                    </span>
                    <img src="../../../assets/images/triangle.png" :class="!isShowGoodsTitle?'downImage':'upImage'">
                </div>
                <van-popup v-model="isShowGoogsName" position="bottom">
                    <van-picker show-toolbar value-key="itemName" :columns="houseConfigurationList[i].configurationInfoList"
                                @cancel="isShowGoogsName = false" @confirm="selectGoodsNameType" />
                </van-popup>
                <!--            分割线3-->
                <div class="ruleOne"></div>
                <!--            数量-->
                <div class="configuration">
<!--                    <div :class="houseConfigurationList[i].count==''?grayDot:redDot"></div>-->
                    <span class="configurationCount">数量</span>
                    <span class="rule">|</span>
                    <div class="placeholderDiv">
                    </div>
                    <div style="margin-left: -1.1rem;">
                        <input type="text" class="count" v-model="houseConfigurationList[i].count" disabled="disabled">
                    </div>
                </div>

                <div class="ruleOne" v-if="mold=='1'"></div>
                <div class="configuration" v-if="mold=='1'">
<!--                    <div :class="houseConfigurationList[i].name==''?grayDot:redDot" ></div>-->
                    <span class="configurationName" >状态</span>
                    <span class="rule" >|</span>
                    <span class="configurationSelect" @click="showStateName(i,item)">
                        {{houseConfigurationList[i].stateName}}
                    </span>
                    <img src="../../../assets/images/triangle.png" :class="!isShowStateTitle?'downImage':'upImage'">
                </div>
                <van-popup v-model="isShowStateName" position="bottom">
                    <van-picker show-toolbar value-key="dictionaryTitle" :columns="configurationStateList"
                                @cancel="isShowStateName = false" @confirm="selectStateNameType" />
                </van-popup>
                <!--            分割线4-->
                <div class="ruleOne"></div>
                <!--            上传图片-->
                <div class="upLoadImg" >
                    <van-uploader v-model="houseConfigurationList[i].houseFileList" multiple
                                  accept="image/*"
                                  preview-size="80px"
                                  :after-read="afterReadTest"
                                  @delete="deletePreview"
                                  style="padding-left: 0.25rem"/>
                </div>
                <div class="upLoadText">
                    <span >*请务必上传</span>
                </div>
                <!--            分割线5-->
                <div class="ruleOne"></div>
                <!--                备注-->
                <textarea class="textareaRemarks" v-model="houseConfigurationList[i].desc" placeholder="描述"></textarea>
                <div>

                </div>
            </div>
        </div>
        <!--        新增条目信息-->
        <div class="newAdd" @click="addInfo()">
            <u >+ 新增条目</u>
        </div>
        <!--            分割线6-->



        <!--        上传视频-->
        <div class="waterMeterTitle" v-if="mold==1">
            <span class="redText">上传视频</span>
        </div>
        <!--       燃气 表交割信息 -->
        <div class="waterMeterDeliveryInfoVideo" v-if="mold==1" style="display: flex;align-items: center;flex-wrap: wrap">

            <!--            上传视频-->
            <van-uploader v-model="waterMeterFileList5" multiple v-if="videoUrlList.length<5"
                          :class="uploadVedio?'upLoadImg-vedioHide':'upLoadImg'"
                          :max-count="5"
                          accept="video/*"
                          preview-size="93px"
                          :before-read="beforeReadTestVideo"
                          :after-read="afterReadTestVideo" >
            </van-uploader>
            <van-loading size="24px" type="spinner" vertical v-if="uploadVedio">已上传 {{uploadProgressBar}}%</van-loading>
            <div v-for="(item,index) in videoUrlList" :key="index" >
              <video
                style="width:93px;height:93px;margin: 35px 15px 0px"    controls="controls" loop="loop"
                :src="item.path"
              ></video>
<!--              <span class="remove_video"  @click="removeVideo(index)" style="position:absolute;bottom:50px;right:10px;font-size:18px">X</span>-->
              <div style="width: 20px;height: 20px;border-radius: 20px;background-color: #999999;color: #FFFFFF;text-align: center;position: relative;left: 95px;top: -105px;display: flex;align-items: center;justify-content: center;font-size: 15px" @click="removeVideo(index)">
                X
              </div>
            </div>
            <div  class="upLoadText">
                <span >*请务必上传</span>
            </div>
        </div>

        <!--        保存按钮      ||!countTemp||!selectTemp-->
<!--        <button :class="isClick() ? redsaveBtn : graySaveBtn "-->
<!--                :disabled="!isClick()"-->
<!--                @click="saveInfo">-->
<!--            保存-->
<!--        </button>-->
        <button class=" redsaveBtn " :disabled="saveingStatus" @click="saveInfo">{{saveingStatus == true ? '保存中': '保存'}}</button>
    </div>


</template>

<script>
import * as OSS from 'ali-oss'

import axios from 'axios'
    import { NavBar,Uploader,Toast ,Picker, Popup,Loading } from 'vant';
    import {
        addContractproperty,
        base64Upload, findContractproperty, getMeterNumberAndMore, queryAllocation,
        queryAllocationClass,updateVideo,delVideo,browseSetHouseItem,queryBaseData,queryContractproperty
    } from "../../../getData/getData";
    // import {
    //  imgPreview
    // } from "../../../libs/imgPreview";
    import {checkAndroid, checkIOS, getStaffId,dealImage,dealImageInfo, responseUtil} from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    /********判断H5页面是否在web容器中 start*********/
    function openInWebview () {/* 返回true或false; */
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') { // 微信浏览器判断
            return false;
        } else if (ua.match(/QQ/i) == 'qq') { // QQ浏览器判断
            return false;
        } else if (ua.match(/WeiBo/i) == "weibo") {
            return false;
        } else {
            if (ua.match(/Android/i) != null) {
                return ua.match(/browser/i) == null;
            } else if (ua.match(/iPhone/i) != null) {
                return ua.match(/safari/i) == null;
            } else {
                return (ua.match(/macintosh/i) == null && ua.match(/windows/i) == null);
            }
        }
    }
    /********判断H5页面是否在web容器中 start*********/


    export default {
        components: {
            [NavBar .name]:NavBar,
            [Uploader .name]:Uploader,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [Toast .name]:Toast,
            rxNavBar,
            [Loading .name]:Loading,
        },
        data() {
            return {
                flagTitle: "入住交割单",
                setHouse_id : '',
                roomHouse_id : '',
                saveingStatus: false,   //控制保存按钮状态，防止二次点击  默认为保存，可点击
                acceptType:'image/*',
                loadingFlag:false,//上传图片loading
                isShowWater:false,//是否显示水表号
                isShowHotWater:false,//是否显示热水表号
                isShowElectric:false,//是否显示电表号
                isShowGas:false,//是否显示煤气号
                isShowGoodsTitle:false,//显示区域
                isShowRoom:false,//显示商品标题
                isShowGoodsName:false,//显示商品名称
                isShowGoogsName:false,//是否显示商品具体名称
                index:0,//记录物品数组下标
                contractId: '',
                type:'',
                contracthistoryId:'',
                propertyType:'',
                list1: [
                    {dictionaryTitle: '水表号' , dictionaryValue: '0'},
                    {dictionaryTitle: '联合收费处' , dictionaryValue: '1'}
                ],
                type1: {dictionaryTitle: '水表号' , dictionaryValue: '0'},
                //表单前面的小点点
                dotOne : '',
                dotTwo : '',
                //水表图片的List
                waterMeterFileList: [],
                waterMeterRemarks:'',
                list2: [
                    {dictionaryTitle: '热水表号' , dictionaryValue: '0'},
                    {dictionaryTitle: '联合收费处' , dictionaryValue: '1'}
                ],
                type2: {dictionaryTitle: '热水表号' , dictionaryValue: '0'},
                dotOne2 : '',
                dotTwo2 : '',
                waterMeterFileList2: [],
                hotWaterMeterRemarks:'',
                list3: [
                    {dictionaryTitle: '电表号' , dictionaryValue: '0'},
                    {dictionaryTitle: '联合收费处' , dictionaryValue: '1'}
                ],
                type3: {dictionaryTitle: '电表号' , dictionaryValue: '0'},
                dotOne3 : '',
                dotTwo3 : '',
                waterMeterFileList3: [],
                wattMeterRemarks:'',
                list4: [
                    {dictionaryTitle: '燃气表号' , dictionaryValue: '0'},
                    {dictionaryTitle: '联合收费处' , dictionaryValue: '1'}
                ],
                type4: {dictionaryTitle: '燃气表号' , dictionaryValue: '0'},
                dotOne4 : '',
                dotTwo4 : '',
                description:'',
                waterMeterFileList4: [],
                gasMeterRemarks:'',
                waterMeterFileList5: [],
                countTemp:'',
                selectTemp: '',
                show : false,
                disabled : false,
                dotThree : true,
                dotFour : true,
                grayDot : 'grayDot',
                redDot : 'redDot',
                //房屋配置信息里名称的select
                // select :'1',
                typeInfoList: [],
                roomTypeList: [],

                //房屋配置信息里数量的变量
                // count: '1',
                //房屋配置的div
                houseConfigurationList : [
                    {
                        count:1,
                        type: '',
                        roomName:'',//区域
                        goodsAllocationClassName:'',//商品标题
                        goodsName:'',//商品名称
                        name:'',
                        configurationInfoList:[],
                        //房屋图片的List
                        houseFileList : [],
                        desc: '',

                    }
                ],
                //彩墙钥匙等特殊项目
              pricingTagMap:[],
              pricingTagPublicMap:[],
                roomSpecialProjectsList:[],
                publicSpecialProjectList:[],
                //    保存按钮变色
                graySaveBtn : 'graySaveBtn',
                redsaveBtn : 'redsaveBtn',
                //winWidth: window.innerWidth,
                //winHeight: window.innerHeight,
                videourl:'',
                videoId:'',   //要删除的视频id
                mold:'',        //1代表租客，2代表业主
                uploadVedio:false,    //是否上传了视频
                uploadProgressBar:0,    //上传视频的进度
                videoUrlList:[],
                configurationStateList:[
                    {id:186,dictionaryTitle:'正常'},
                    //{id:187,dictionaryTitle:'报废'},
                    {id:188,dictionaryTitle:'丢失'},
                    //{id:189,dictionaryTitle:'赠送'},
                    //{id:190,dictionaryTitle:'退回'},
                    {id:740,dictionaryTitle:'损坏'}],
                isShowStateTitle:false,
                isShowStateName:false,
                hotWaterMeterInfo:'',

                isShowStatusList:false,
                isShowExist:false,
                isShowPublicExist:false,
                isShowPublicStatusList:false,
                existInfoList:[
                    {
                        value:'1',
                        label:'有'
                    },
                    {
                        value:'0',
                        label:'无'
                    },
                ],
                statusInfoList:[
                  {id:186,dictionaryTitle:'正常'},
                  {id:188,dictionaryTitle:'丢失'},
                  {id:740,dictionaryTitle:'损坏'}
                ],
            }
        },
        created() {
            if (openInWebview()) {
                if(checkIOS()){
                    this.acceptType = 'image/*';
                }else if(checkAndroid()){
                    this.acceptType = 'video/*';
                }else{
                    this.acceptType = 'image/*';
                }
            }
        },
        mounted() {
            this.initData()
            this.initDictionaryData()
            this.roomHouse_id = this.$route.query.roomHouse_id || ''   //用于初始化房屋的水表号等
            this.setHouse_id = this.$route.query.setHouse_id || ''
            this.contractId = this.$route.query.contractId || ''
            this.contractPropertyId = this.$route.query.contractPropertyId || ''

            //0代表添加，1代表修改
            this.type = this.$route.query.type
            this.contracthistoryId = this.$route.query.contracthistoryId
            //1 表明租约变更了
            this.propertyType =this.$route.query.propertyType
            this.mold=this.$route.query.mold    //1租客2业主
            this.houseType = this.$route.query.houseType  //房源类型0整租1合租2集寓
          if( this.type =='1'){
                this.initContractproperty()
            }else if(this.type =='0' && this.setHouse_id != ''){
                this.getMeterNumberAndMore();
            }
            if(this.mold==1&&this.type=='0'){
                this.getDecorationCheck()
            }

            if(this.$route.query.flag == "ruzhu"){
                if(this.type == '0'){
                    this.flagTitle = "添加入住交割单"
                }else if(this.type == '1'){
                    this.flagTitle = "修改入住交割单"
                }

            } else if(this.$route.query.flag == "jiechu"){
                if(this.type == '0'){
                    this.flagTitle = "添加解除交割单"
                }else if(this.type == '1'){
                    this.flagTitle = "修改解除交割单"
                }
            }

            if(this.mold == "1"){//1租客2业主
              this.queryContractproperty()
            }

        },
        methods : {
            getDecorationCheck(){
                let that=this
                let initData={}
                initData.setHouse_id=that.setHouse_id
                initData.roomHouse_id=that.roomHouse_id
                initData.status='186'
                initData.user_id=getStaffId()
                console.log(initData)
                browseSetHouseItem(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.houseConfigurationList=response.data.data.date
                        for(let i in that.houseConfigurationList){
                            that.houseConfigurationList[i].goodsName=that.houseConfigurationList[i].itemName
                            that.houseConfigurationList[i].name =that.houseConfigurationList[i].item_id
                            that.houseConfigurationList[i].source='1'
                            that.houseConfigurationList[i].stateId=186
                            that.houseConfigurationList[i].stateName='正常'
                            that.houseConfigurationList[i].configurationInfoList=[]
                            if(!that.houseConfigurationList[i].houseFileList){
                                that.houseConfigurationList[i].houseFileList=[]
                            }
                            if(!that.houseConfigurationList[i].count){
                                that.houseConfigurationList[i].count=1
                            }
                        }
                        // 入住交割单时，配置项browseSetHouseItem取pricingTagMap，有值，无的筛除不显示；没有值，去queryBaseData遍历，全都显示
                      // 修改交割单时，findContractproperty取roomPricingTagDetail，全都显示，addOrUpdate==‘1’标识修改
                      // 保存时的roomSpecialProjectsList全都是需要校验的
                      that.pricingTagMap = response.data.data.pricingTagMap.data?response.data.data.pricingTagMap.data:[]    //添加入住时获取的配置项
                      that.pricingTagPublicMap = response.data.data.pricingTagPublicMap.data?response.data.data.pricingTagPublicMap.data:[]
                            that.queryBaseData()
                      that.roomSpecialProjectsList = []
                      that.publicSpecialProjectList = []

                            for(let i in that.pricingTagMap){
                              if(that.pricingTagMap[i].pricingTagFlag=='1'){   //有
                                that.pricingTagMap[i].id=that.pricingTagMap[i].characteristic_id
                                that.pricingTagMap[i].isExist =that.pricingTagMap[i].pricingTagFlag
                                that.pricingTagMap[i].isExistName =that.pricingTagMap[i].pricingTagFlagStr
                                that.pricingTagMap[i].source='1'   //0新增，1修改
                                if(!that.pricingTagMap[i].houseFileList){
                                  that.pricingTagMap[i].houseFileList=[]
                                }
                                that.roomSpecialProjectsList.push(that.pricingTagMap[i])
                              }
                            }
                      console.log('browseSetHouseItem',that.roomSpecialProjectsList)

                            for(let i in that.pricingTagPublicMap){
                              if(that.pricingTagPublicMap[i].pricingTagFlag=='1'){   //有
                                that.pricingTagPublicMap[i].id=that.pricingTagPublicMap[i].characteristic_id
                                that.pricingTagPublicMap[i].isExist =that.pricingTagPublicMap[i].pricingTagFlag
                                that.pricingTagPublicMap[i].isExistName =that.pricingTagPublicMap[i].pricingTagFlagStr
                                that.pricingTagPublicMap[i].source='1'   //0新增，1修改
                                if(!that.pricingTagPublicMap[i].houseFileList){
                                  that.pricingTagPublicMap[i].houseFileList=[]
                                }
                                that.publicSpecialProjectList.push(that.pricingTagPublicMap[i])
                              }
                            }
                    })
                })
            },
            queryBaseData(){
                let that = this
                let initData = {}
                initData.user_id = getStaffId()
                initData.dbName = ['pricingTag']
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {

                      if(that.pricingTagMap.length==0){  //原本如果接口没有检查项，会把字典项匹配进去======》后来改成：原本如果接口没有检查项，就是没配置，会把字典项匹配进去，后台有值就是配置过，只显示有的
                        that.roomSpecialProjectsList = JSON.parse(JSON.stringify(response.data.data.pricingTag))
                        for(let i in that.roomSpecialProjectsList){
                          that.roomSpecialProjectsList[i].canChange='1'   //是否能修改，1能 其他不能
                          that.roomSpecialProjectsList[i].isExist ='0'
                          that.roomSpecialProjectsList[i].isExistName ='无'
                          if(!that.roomSpecialProjectsList[i].houseFileList){
                            that.roomSpecialProjectsList[i].houseFileList=[]
                          }
                        }
                      }
                      console.log('queryBaseData',that.roomSpecialProjectsList)
                      if(that.pricingTagPublicMap.length==0){
                        that.publicSpecialProjectList = JSON.parse(JSON.stringify(response.data.data.pricingTag))  //字典项里所有的检查项

                        for(let i in that.publicSpecialProjectList){
                          that.publicSpecialProjectList[i].canChange='1'   //是否能修改，1能 其他不能
                          that.publicSpecialProjectList[i].isExist='0'
                          that.publicSpecialProjectList[i].isExistName='无'
                          if(!that.publicSpecialProjectList[i].houseFileList){
                            that.publicSpecialProjectList[i].houseFileList=[]
                          }
                        }
                      }

                    })
                })
            },
            initDictionaryData(){
                let that = this
                let initData = {}
                initData.user_id = getStaffId()
                initData.fdName = ['CONTRACTPROPERTYELECTRICMETERMAP','CONTRACTPROPERTYWATERMETERMAP','CONTRACTPROPERTYGASMETERMAP','CONTRACTPROPERTYHOTWATERMETERMAP']
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.list3 = response.data.data.CONTRACTPROPERTYELECTRICMETERMAP  //电表
                        that.list1 = response.data.data.CONTRACTPROPERTYWATERMETERMAP  //水表
                        that.list2 = response.data.data.CONTRACTPROPERTYHOTWATERMETERMAP  //热水表
                        that.list4 = response.data.data.CONTRACTPROPERTYGASMETERMAP  //燃气表
                    })
                })
            },
            queryContractproperty(){
                let that = this
                let initData = {}
                initData.roomHouse_id = that.roomHouse_id
                queryContractproperty(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.roomTypeList = response.data.data.data
                        console.log("roomTypeList:",that.roomTypeList)

                    })
                })
            },
            removeVideo(index){
                this.waterMeterFileList5=[]
                if(!this.videoUrlList[index].videoId){
                    this.videoUrlList.splice(index,1)
                    return
                }
                    var that=this
                    var initData={}
                    initData.id=that.videoUrlList[index].videoId
                    this.videoUrlList.splice(index,1)
                    console.log(initData)
                    delVideo(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                    })
                })
            },

            // 初始化获取水表号等
            getMeterNumberAndMore(){
                let that = this;
                let initData = {};
                initData.setHouse_id = that.setHouse_id
                initData.roomHouse_id=that.roomHouse_id
                getMeterNumberAndMore(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        let wattMeterInfo = response.data.data.wattMeterInfo.data  //电表
                        let waterMeterInfo = response.data.data.waterMeterInfo.data  //水表
                        let gasMeterInfo = response.data.data.gasMeterInfo.data   //燃气表
                        that.hotWaterMeterInfo= response.data.data.hotWaterMeterInfo?response.data.data.hotWaterMeterInfo.data:undefined //热水表

                        if(wattMeterInfo != undefined){
                            that.dotOne3 = wattMeterInfo.equipmentCode
                            that.selectElecType(that.getArrValuesByIndex(that.list3,wattMeterInfo.equipmentCodeType))

                        }

                        if(waterMeterInfo != undefined){
                            that.dotOne = waterMeterInfo.equipmentCode
                            that.selectWaterType(that.getArrValuesByIndex(that.list1,waterMeterInfo.equipmentCodeType))
                        }

                        if(gasMeterInfo != undefined){
                            that.dotOne4 = gasMeterInfo.equipmentCode
                            // console.log(gasMeterInfo.equipmentCodeType)
                            that.selectGasType(that.getArrValuesByIndex(that.list4,gasMeterInfo.equipmentCodeType))
                        }

                        if(that.hotWaterMeterInfo != undefined){
                             that.dotOne2=that.hotWaterMeterInfo.equipmentCode
                             that.selectHotWaterType(that.getArrValuesByIndex(that.list2,that.hotWaterMeterInfo.equipmentCodeType))
                        }
                    })
                })
            },

            //根据id或者dictionaryValue 获取对应数组的值
            getArrValuesByIndex(arr,dictionaryValue){
                for(let i=0;i<arr.length;i++){
                    if(arr[i].dictionaryValue==dictionaryValue){
                        return arr[i]
                    }
                }
                return ''
            },
            //水表
            selectWaterType(item){
                this.type1 = item
                this.isShowWater = false
            },
            //热水表
            selectHotWaterType(item){
                this.type2 = item
                this.isShowHotWater = false
            },
            //电表
            selectElecType(item){
                this.type3 = item
                this.isShowElectric = false
            },
            //煤气表
            selectGasType(item){
                this.type4 = item
                this.isShowGas = false
            },
            //商品分类选择
            chooseOne(i,item){
                if(item.source=='1'){
                      return
                }
                this.index = i
                this.isShowGoodsTitle = !this.isShowGoodsTitle
            },
            //区域选择
            chooseRoom(i,item){
                if(item.source=='1'){
                      return
                }
                this.index = i
                this.isShowRoom = !this.isShowRoom
            },
            //选择区域下拉
            selectRoomType(item){
                console.log(item)
                this.houseConfigurationList[this.index].roomName = item.roomName
                this.houseConfigurationList[this.index].roomHouse_id = item.roomHouse_id
                //清空原有商品的名字
                // this.houseConfigurationList[this.index].goodsName = ''
                // this.selectChange(this.index)
                this.isShowRoom = false
            },
            chooseExist(i,item){
                // if(item.source=='1'){  //
                //   return
                // }
                this.index = i
                this.isShowExist = !this.isShowExist
            },
            choosePublicExist(i,item){
                // if(item.source=='1'){  //
                //   return
                // }
                this.index = i
                this.isShowPublicExist = !this.isShowPublicExist
            },
            showGoodsName(i,item){
                if(item.source=='1'){
                     return
                }
                this.index = i
                this.isShowGoogsName = !this.isShowGoogsName
            },
            showStateName(i,item){
                console.log(i)
                console.log(item)
                this.index = i
                this.isShowStateName = !this.isShowStateName
            },
            showStatusList(i,item){
              console.log(i)
              console.log(item)
              this.index = i
              this.isShowStatusList = !this.isShowStatusList
              },
            showPublicStatusList(i,item){
                console.log(i)
                console.log(item)
                this.index = i
                this.isShowPublicStatusList = !this.isShowPublicStatusList
            },
            //选择商品分类下拉
            selectGoodsTitleType(item){
                console.log(item)
                this.houseConfigurationList[this.index].goodsAllocationClassName = item.goodsAllocationClassName
                this.houseConfigurationList[this.index].type = item.id
                //清空原有商品的名字
                this.houseConfigurationList[this.index].goodsName = ''
                this.selectChange(this.index)
                this.isShowGoodsTitle = false
            },
            //是否存在
            selectExistType(item){
              console.log(this.roomSpecialProjectsList)
              console.log(this.publicSpecialProjectList)
                this.roomSpecialProjectsList[this.index].isExist = item.value
                this.roomSpecialProjectsList[this.index].isExistName = item.label
                this.isShowExist = false
            },
            selectPublicExistType(item){
              console.log(895)
                this.publicSpecialProjectList[this.index].isExist = item.value
                this.publicSpecialProjectList[this.index].isExistName = item.label
                this.isShowPublicExist = false
            },
            //选择商品具体名字
            selectGoodsNameType(item){
                this.houseConfigurationList[this.index].goodsName = item.itemName
                this.houseConfigurationList[this.index].name = item.id
                this.isShowGoogsName = false
            },
            //选择状态
            selectStateNameType(item){
                this.houseConfigurationList[this.index].stateName = item.dictionaryTitle
                this.houseConfigurationList[this.index].stateId = item.id
                this.isShowStateName = false
            },
            selectStatusType(item){
                this.roomSpecialProjectsList[this.index].stateName = item.dictionaryTitle
                this.roomSpecialProjectsList[this.index].stateId = item.id
                this.isShowStatusList = false
            },
            selectPublicStatusType(item){
                this.publicSpecialProjectList[this.index].stateName = item.dictionaryTitle
                this.publicSpecialProjectList[this.index].stateId = item.id
                this.isShowPublicStatusList = false
            },
            //物业交割单数据加载
            initContractproperty(){
                let that = this
                let initData={}
                let contractId = that.contractId
                let contractHistoryId = that.contracthistoryId
                let proType = that.propertyType
                initData.contractPropertyId = this.contractPropertyId
                if(proType=='1'){
                    initData.contractId = contractId
                } else {
                    initData.contractId = contractId
                    initData.contracthistoryId = contractHistoryId
                    initData.isByContractHistory = true
                }
                initData.fillNew = '1'
                findContractproperty(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let info = response.data.data.contractProperty
console.log(info)
                        // 水表号
                        that.dotOne = info.waterMeterCode
                        // 水表底数
                        that.dotTwo = info.waterMeterNumber
                        that.type1.dictionaryTitle = info.waterMeterCodeTypeStr
                        that.type1.dictionaryValue = info.waterMeterCodeType
                        // if(info.waterMeterCodeType=='1'){
                        //     that.type1.dictionaryTitle = '联合收费处'
                        //     that.type1.dictionaryValue = '1'
                        // } else {
                        //     that.type1.dictionaryTitle = '水表号'
                        //     that.type1.dictionaryValue = '0'
                        // }
                        //水表底数图片
                        if(info.waterMeterUrl){
                            that.waterMeterFileList.push({content:info.waterMeterUrl,url:info.waterMeterUrl})
                        }
                        // 水表备注
                        that.waterMeterRemarks = info.waterMeterRemarks
                        // 热水表号
                        that.dotOne2 = info.hotWaterMeterCode
                        if(that.dotOne2){
                             that.hotWaterMeterInfo=true
                        }
                        // 热水表底数
                        that.dotTwo2 = info.hotWaterMeterNumber
                        that.type2.dictionaryTitle = info.hotWaterMeterCodeTypeStr
                        that.type2.dictionaryValue = info.hotWaterMeterCodeType
                        // if(info.hotWaterMeterCodeType=='1'){
                        //     that.type2.dictionaryTitle = '联合收费处'
                        //     that.type2.dictionaryValue = '1'
                        // } else {
                        //     that.type2.dictionaryTitle = '热水表号'
                        //     that.type2.dictionaryValue = '0'
                        // }
                        // 热水表底数图片
                        if(info.hotWaterMeterUrl){
                            that.waterMeterFileList2.push({content:info.hotWaterMeterUrl,url:info.hotWaterMeterUrl})
                        }
                        // 热水表备注
                        that.hotWaterMeterRemarks = info.hotWaterMeterRemarks
                        // 电表号
                        that.dotOne3 = info.wattMeterCode
                        // 电表底数
                        that.dotTwo3 = info.wattMeterNumber
                        that.type3.dictionaryTitle = info.wattMeterCodeTypeStr
                        that.type3.dictionaryValue = info.wattMeterCodeType
                        // if(info.wattMeterCodeType=='1'){
                        //     that.type3.dictionaryTitle = '联合收费处'
                        //     that.type3.dictionaryValue = '1'
                        // } else {
                        //     that.type3.dictionaryTitle = '电表号'
                        //     that.type3.dictionaryValue = '0'
                        // }
                        // 电表底数图片
                        if(info.wattMeterUrl){
                            that.waterMeterFileList3.push({content:info.wattMeterUrl,url:info.wattMeterUrl})
                        }
                        // 电表备注
                        that.wattMeterRemarks = info.wattMeterRemarks
                        // 燃气表号
                        that.dotOne4 = info.gasMeterCode
                        // 燃气表底数
                        that.dotTwo4 = info.gasMeterNumber
                        that.type4.dictionaryTitle = info.gasMeterCodeTypeStr
                        that.type4.dictionaryValue = info.gasMeterCodeType
                      console.log(that.type4.dictionaryTitle)
                        // if(info.gasMeterCodeType=='1'){
                        //     that.type4.dictionaryTitle = '联合收费处'
                        //     that.type4.dictionaryValue = '1'
                        // } else {
                        //     that.type4.dictionaryTitle = '燃气表号'
                        //     that.type4.dictionaryValue = '0'
                        // }
                        that.description = info.description
                        // 燃气表底数图片
                        if(info.gasMeterUrl){
                            that.waterMeterFileList4.push({content:info.gasMeterUrl,url:info.gasMeterUrl})
                        }
                        // 燃气表备注
                        that.gasMeterRemarks = info.gasMeterRemarks
                      console.log(info.roomPricingTagDetail)
                        //检查项
                        let roomPricingTagDetail = info.roomPricingTagDetail?info.roomPricingTagDetail:[]
                        let publicPricingTagDetail = info.publicPricingTagDetail?info.publicPricingTagDetail:[]

                        for(let i in roomPricingTagDetail){
                            let id=roomPricingTagDetail[i].pricingTag_id
                            let dictionaryTitle = roomPricingTagDetail[i].pricingTagName
                            let isExist = roomPricingTagDetail[i].itemCount
                            let isExistName =roomPricingTagDetail[i].itemCount==1?'有':'无'
                            let stateId = roomPricingTagDetail[i].status
                            let stateName = roomPricingTagDetail[i].dictionaryTitle
                            let source = roomPricingTagDetail[i].source
                            let desc = roomPricingTagDetail[i].itemDescription
                            let dictionaryDescription = roomPricingTagDetail[i].dictionaryDescription
                            let addOrUpdate = '1'  //1修改
                            let imgList = []
                            for(let j=0;j<roomPricingTagDetail[i].photoList.length;j++){
                                imgList.push({content:roomPricingTagDetail[i].photoList[j].path,url:roomPricingTagDetail[i].photoList[j].path})
                            }
                            that.roomSpecialProjectsList.push({   //修改物业交割单
                                id:id,
                                dictionaryTitle:dictionaryTitle,
                                isExist:isExist,
                                isExistName:isExistName,
                                stateId:stateId,
                                stateName:stateName,
                                houseFileList:imgList,
                                desc:desc,
                                source:source,
                                addOrUpdate:addOrUpdate,
                                dictionaryDescription:dictionaryDescription})
                        }
                      console.log(that.roomSpecialProjectsList)
                        for(let i in publicPricingTagDetail){
                            let id=publicPricingTagDetail[i].pricingTag_id
                            let dictionaryTitle = publicPricingTagDetail[i].pricingTagName
                            let isExist = publicPricingTagDetail[i].itemCount
                            let isExistName =publicPricingTagDetail[i].itemCount==1?'有':'无'
                            let stateId = publicPricingTagDetail[i].status
                            let stateName = publicPricingTagDetail[i].dictionaryTitle
                            let source = publicPricingTagDetail[i].source
                          let addOrUpdate = '1'  //1修改
                          let desc = publicPricingTagDetail[i].itemDescription
                            let dictionaryDescription = publicPricingTagDetail[i].dictionaryDescription
                            let imgList = []
                            for(let j=0;j<publicPricingTagDetail[i].photoList.length;j++){
                              imgList.push({content:publicPricingTagDetail[i].photoList[j].path,url:publicPricingTagDetail[i].photoList[j].path})
                            }
                            that.publicSpecialProjectList.push({
                              id:id,
                              dictionaryTitle:dictionaryTitle,
                              isExist:isExist,
                              isExistName:isExistName,
                              stateId:stateId,
                              stateName:stateName,
                              houseFileList:imgList,
                              desc:desc,
                              source:source,
                              addOrUpdate:addOrUpdate,
                              dictionaryDescription:dictionaryDescription})
                        }
                        //配置列表
                        let list = info.contractPropertyDetail
                        if(list){
                            that.houseConfigurationList = []
                            for(let i=0;i<list.length;i++){
                                let name = list[i].item_id
                                let count = list[i].itemCount
                                let desc = list[i].itemDescription
                                let imgList = []
                                let type = list[i].type
                                let roomName = list[i].roomName
                                let title = list[i].itemTitle
                                let itemName = list[i].itemName
                                let source=list[i].source
                                let status=list[i].status
                                let dictionaryTitle=list[i].dictionaryTitle
                                for(let j=0;j<list[i].photoList.length;j++){
                                    imgList.push({content:list[i].photoList[j].path,url:list[i].photoList[j].path})
                                }
                                that.houseConfigurationList.push({name:name,count:count,desc:desc,houseFileList:imgList,type:type,roomName:roomName,configurationInfoList:[],
                                    goodsName:itemName,goodsAllocationClassName:title,stateId:status?status:186,stateName:dictionaryTitle?dictionaryTitle:'正常',source:source})
                                that.selectChange(i)
                            }
                        }
                        if(info.propertyphoneList.length>0){
                            that.videoUrlList=info.propertyphoneList
                            for(let i in that.videoUrlList){
                                   that.videoUrlList[i].path=that.videoUrlList[i].videoPath
                            }
                        }
                        console.log(that.houseConfigurationList)
                    })
                })
            },

            initData() {
                let that = this
                let initData = {
                    itemType: 1
                }
                queryAllocationClass(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.typeInfoList = response.data.data.data
                    })
                })
            },

            //物品配置图片多选上传
            // afterReadTest(file){
            //     // this.loadingFlag = true;
            //     let fileLength = parseInt(file.length) - parseInt(1)
            //     if(file.length > 1){
            //         for(let i = 0; i<file.length;i++){
            //             let fileDetail = file[i];
            //             this.afterRead(fileDetail,i,fileLength);
            //         }
            //     }else{
            //         this.afterRead(file);
            //     }
            // },
            deletePreview(file){
                this.$forceUpdate()
            },
            afterReadTest(file){
                console.log(file,"上传图片=========")
                // if (!Array.isArray(file)) {
                //     // 判断是否只有一张，则转为数组
                //     file = [file]
                // }
                // for (let i = 0; i < file.length; i++) {
                //     console.log('压缩之前图片大小', file[i].file.size)
                //     imgPreview(file[i].file, async (files) => {


                //         let fileUrl = files;
                //         console.log(files,"files=1=1=1=1==1=1")
                //         let formData = new FormData();
                //         formData.append("file", fileUrl);
                //         formData.append("key", "Gn1xVdBiTClSSHKZifg0pTQSU5XGagWO");
                //         // this.afterRead()

                //     })
                // }

                // return


                // this.loadingFlag = true;
                let fileLength = parseInt(file.length) - parseInt(1)
                console.log(file,'=============-=-=')
                if(file.length > 1){
                    for(let i = 0; i<file.length;i++){
                        // console.log('压缩之前图片大小', file[i].file.size)
                        let fileDetail = file[i];
                        dealImageInfo(fileDetail,fileDetail.content,400,this.afterRead,i,fileLength);
                    }
                }else{
                    dealImageInfo(file,file.content,400,this.afterRead);
                }




            },

            afterRead(file,base64Str,i,fileLength){
                let that = this
                console.log(base64Str,"base64Str===========")
                let initData = {
                    base64: base64Str
                }
                that.loadingFlag = true,
                    base64Upload(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            // debugger
                            file.content = response.data.data.path
                            console.log(file.content,"上传后")
                            // that.loadingFlag = false
                            if(i == fileLength){
                                that.loadingFlag = false
                            }
                        })
                    })
            },
            beforeReadTestVideo(file){
                this.uploadProgressBar=0
                this.uploadVedio=true
                return true
            },
            afterReadTestVideo(file){
                if(file.file.size>5368709120){
                    this.waterMeterFileList5=[]
                    responseUtil.alertMsg(that,'上传视频太大，视频需小于5G')
                    return
                }
                var that=this
                var initData={}
                updateVideo(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.afterReadALi(response.data.data,file.file)
                    })
                })
            },
            afterReadALi(data,file){
                let that=this
                var regionName=data.END_POINT
                let client = new OSS({
                   // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
                   accessKeyId: data.ACCESS_ID,
                   accessKeySecret: data.ACCESS_KEY,
                   // 填写Bucket名称。
                   bucket: data.bucketName,//'himitest',
                   region: regionName.substring(7,21)//'oss-cn-beijing',
                });
                let yy = new Date().getFullYear();
                let mm = new Date().getMonth()+1;
                let fileName = "video/"+yy+'/'+mm+"/"+file.name
                // 'object'填写从OSS下载的object名称，即不包括Bucket名称在内的Object的完整路径。
                // 'localfile'填写下载到本地文件的完整路径。
                const progress = (p, _checkpoint) => {
                    // Object的上传进度。
                    that.uploadProgressBar=parseInt(100*p)
                    // 分片上传的断点信息。
                }
              console.log(file)
                client.multipartUpload(fileName, file,{progress}).then(function (r1) {
                   //that.videourl=r1.res.requestUrls[0].split('?')[0]
                   let img={path:r1.res.requestUrls[0].split('?')[0]}
                   that.videoUrlList.push(img)
                   that.uploadVedio=false
                   that.waterMeterFileList5=[]
                }).then(function (r2) {
                   //console.log('get success: %j', r2);
                }).catch(function (err) {
                    responseUtil.alertMsg(that,'视频上传失败')
                    that.uploadVedio=false
                   //console.error('error: %j', err);
               });
                /*client.put(fileName, file).then(function (r1) {
                   //that.waterMeterFileList5[0].content=r1.res.requestUrls[0]
                   that.videourl=r1.res.requestUrls[0]
                   console.log(r1)
                   console.log(that.videourl)
                }).then(function (r2) {
                   //console.log('get success: %j', r2);
                }).catch(function (err) {
                   //console.error('error: %j', err);
               });*/
            },
            //点击后退方法
            onClickLeft(){
                this.$router.go(-1)
            },
            //房屋配置里数量点击增加与减少
            sub(i){
                let count = parseInt(this.houseConfigurationList[i].count)
                if(count<=1){
                    responseUtil.alertMsg(this,'物品数量不能小于1')
                    return
                }
                count--
                this.houseConfigurationList[i].count = count
            },
            add(i){
                let count = parseInt(this.houseConfigurationList[i].count)
                count++
                this.houseConfigurationList[i].count = count
            },
            //    新增条目的点击方法
            addInfo(){
                this.houseConfigurationList.push({count:1,
                    type: '',
                    name:'',
                    configurationInfoList :[],
                    //房屋图片的List
                    houseFileList : [],
                    desc: '',
                    goodsName:'',
                    goodsAllocationClassName:'',
                    roomName:'',//区域
                });
            },
            //删除条目的点击方法
            cancelBtn(i){
                this.houseConfigurationList.splice(i,1);  //删除index为i,位置的数组元素
                this.index = 0
            },

            //检测能不能保存物业交割单的校验方法
            canSaveProperty(){
                let that = this
                /*if(that.mold=='1'&&that.videoUrlList.length==0){
                        responseUtil.alertMsg(that,'请上传视频')
                        return false
                } */
                if(that.dotTwo3&&that.dotTwo&&that.dotTwo4){
                    //在进行物品项校验
                    for(let i in that.houseConfigurationList){
                        if(!that.houseConfigurationList[i].goodsAllocationClassName){
                            responseUtil.alertMsg(that,'请输入物品分类')
                            return false
                        }
                        if(!that.houseConfigurationList[i].goodsName){
                            responseUtil.alertMsg(that,'请输入物品名称')
                            return false
                        }
                    }
                    return true
                } else {
                    // if(!that.dotOne3){
                    //     responseUtil.alertMsg(that,'请输入电表号')
                    //     return false
                    // }
                    if(!that.dotTwo3){
                        responseUtil.alertMsg(that,'请输入电表底数')
                        return false
                    }
                    // if(!that.dotOne){
                    //     responseUtil.alertMsg(that,'请输入水表号')
                    //     return false
                    // }
                    if(!that.dotTwo){
                        responseUtil.alertMsg(that,'请输入水表底数')
                        return false
                    }
                    if(that.hotWaterMeterInfo&&!that.dotTwo2){
                        responseUtil.alertMsg(that,'请输入热水表底数')
                        return false
                    }
                    // if(!that.dotOne4){
                    //     responseUtil.alertMsg(that,'请输入燃气表号')
                    //     return false
                    // }
                    if(!that.dotTwo4){
                        responseUtil.alertMsg(that,'请输入燃气表底数')
                        return false
                    }
                    /*if(!that.videourl){
                        responseUtil.alertMsg(that,'请上传视频')
                        return false
                    }
                    return false*/
                }
            },

            //保存按钮促发事件
            saveInfo(){
                let that = this
                //保存之前先判断符不符合保存标准否则直接返回
                let canSave = that.canSaveProperty()
                if(!canSave) return
                let initData = {}
                //合同id
                initData.contractId = that.contractId
                // 员工id
                initData.addStaff_id = getStaffId()
                // 水表号
                initData.waterMeterCode = that.dotOne
                // 水表底数
                initData.waterMeterBase = that.dotTwo
                // 水表底数图片
                if(that.waterMeterFileList[0])
                    initData.waterMeterAcc_url = that.waterMeterFileList[0].content
                // 水表备注
                initData.waterMeterRemarks = that.waterMeterRemarks
                // 热水表号
                initData.hotWaterMeterCode = that.dotOne2
                // 热水表底数
                initData.hotwaterMeterBase = that.dotTwo2
                // 热水表底数图片
                if(that.waterMeterFileList2[0])
                    initData.hotWaterMeterAcc_url = that.waterMeterFileList2[0].content
                // 热水表备注
                initData.hotWaterMeterRemarks = that.hotWaterMeterRemarks
                // 电表号
                initData.wattMeterCode = that.dotOne3
                // 电表底数
                initData.meterBase = that.dotTwo3
                // 电表底数图片
                if(that.waterMeterFileList3[0])
                    initData.wattMeterAcc_url = that.waterMeterFileList3[0].content
                // 电表备注
                initData.wattMeterRemarks = that.wattMeterRemarks
                // 燃气表号
                initData.gasMeterCode = that.dotOne4
                // 燃气表底数
                initData.gasMeter = that.dotTwo4
                // 燃气表底数图片
                if(that.waterMeterFileList4[0])
                    initData.gasMeterAcc_url = that.waterMeterFileList4[0].content
                // 燃气表备注
                initData.gasMeterRemarks = that.gasMeterRemarks
                /*if(that.waterMeterFileList5[0]){
                    initData.picList={}
                    initData.picList.path=that.waterMeterFileList5[0].content
                }*/
                if(that.videoUrlList.length>0){
                    initData.picList=[]
                    initData.picList[0]={}
                    initData.picList=that.videoUrlList
                }
                //描述
                initData.description = that.description
                //添加一个状态信息表明此物业交割单是从合同还是租约变更那里添加的数据
                initData.type = that.propertyType
                //添加历史合同id
                initData.contractHistoryId = that.contracthistoryId
                initData.contractPropertyId = that.contractPropertyId

                initData.waterMeterCodeType = that.type1.dictionaryValue
                initData.hotWaterMeterCodeType = that.type2.dictionaryValue
                initData.wattMeterCodeType = that.type3.dictionaryValue
                initData.gasMeterCodeType = that.type4.dictionaryValue

                //配置列表
                initData.configurelist = []
                for(let i=0;i<that.houseConfigurationList.length;i++){
                    let data = {}
                    data.item_id = that.houseConfigurationList[i].name
                    data.desc = that.houseConfigurationList[i].desc
                    data.itemCount = that.houseConfigurationList[i].count
                    data.source=that.houseConfigurationList[i].source?that.houseConfigurationList[i].source:'0'
                    data.status=that.houseConfigurationList[i].stateId
                    data.roomId=that.houseConfigurationList[i].roomHouse_id
                    data.imgList = []
                    if(that.houseConfigurationList[i].houseFileList.length==0){
                        responseUtil.alertMsg(that,'请上传物品图片')
                        return
                    }
                    for(let j=0;j<that.houseConfigurationList[i].houseFileList.length;j++)
                        data.imgList.push({imgUrl: that.houseConfigurationList[i].houseFileList[j].content})
                        initData.configurelist.push(data)
                }
                if(this.mold=='1'){  //租客才有检查项
                  for(let i=0;i<that.roomSpecialProjectsList.length;i++){
                    let data = {}
                    data.item_id = 0
                    data.pricingTag_id = that.roomSpecialProjectsList[i].id   //检查项id
                    //是否存在
                    data.desc = that.roomSpecialProjectsList[i].desc   //描述
                    data.itemCount = that.roomSpecialProjectsList[i].isExist
                    data.source=that.roomSpecialProjectsList[i].source?that.roomSpecialProjectsList[i].source:'0'  //新增修改
                    data.status=that.roomSpecialProjectsList[i].stateId   //状态
                    data.imgList = []
                    if((!that.roomSpecialProjectsList[i].stateId||that.roomSpecialProjectsList[i].stateId=='')){   //能填的都校验
                      responseUtil.alertMsg(that,'请选择检查项状态')
                      return
                    }
                    if((that.roomSpecialProjectsList[i].houseFileList.length==0)){
                      responseUtil.alertMsg(that,'请上传检查项图片')
                      return
                    }
                    for(let j=0;j<that.roomSpecialProjectsList[i].houseFileList.length;j++)  //图片
                      data.imgList.push({imgUrl: that.roomSpecialProjectsList[i].houseFileList[j].content})

                    initData.configurelist.push(data)

                  }
                  if(that.houseType=='1'){  //合租才有公区检查项
                    for(let i=0;i<that.publicSpecialProjectList.length;i++){
                      let data = {}
                      data.item_id = 0
                      data.pricingTag_id = that.publicSpecialProjectList[i].id   //检查项id
                      //是否存在
                      data.desc = that.publicSpecialProjectList[i].desc   //描述
                      data.itemCount = that.publicSpecialProjectList[i].isExist
                      data.source=that.publicSpecialProjectList[i].source?that.publicSpecialProjectList[i].source:'0'  //新增修改
                      data.status=that.publicSpecialProjectList[i].stateId   //状态
                      data.roomId = '-1'
                      data.imgList = []
                      if((!that.publicSpecialProjectList[i].stateId || that.publicSpecialProjectList[i].stateId=='')){
                        responseUtil.alertMsg(that,'请选择检查项状态')
                        return
                      }
                      if((that.publicSpecialProjectList[i].houseFileList.length==0)){
                        responseUtil.alertMsg(that,'请上传检查项图片')
                        return
                      }
                      for(let j=0;j<that.publicSpecialProjectList[i].houseFileList.length;j++)  //图片
                        data.imgList.push({imgUrl: that.publicSpecialProjectList[i].houseFileList[j].content})

                      initData.configurelist.push(data)

                    }
                  }
                }

                // console.log(initData.wattMeterAcc_url,"wattMeterAcc_url",initData.configurelist)
                //console.log(initData)
                // 改变保存按钮状态，防止二次点击
                // return
                that.saveingStatus = true
                addContractproperty(initData).then(function (response) {
                    that.saveingStatus = false
                    responseUtil.dealResponse(that, response, () => {
                        if(response.data.code==0){
                            responseUtil.alertMsg(that,'添加成功')
                            that.$router.go(-1)
                        }
                    })
                })
            },
            selectChange(val){
              console.log(val)
                //this.selectTemp=this.houseConfigurationList[val].select
                let that = this
                let initData = {
                    goodsAllocationClass_id: that.houseConfigurationList[val].type,
                    isOwner:that.mold   //1租客，2业主
                }
                queryAllocation(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.houseConfigurationList[val].configurationInfoList = response.data.data.data
                    })
                })
            }
        }
    }

</script>

<style lang="less" scoped >

    .addProperty{
        overflow: hidden;
        width: 100%;
    }
    //水表交割标题
    .waterMeterTitle{
        width: 92%;
        margin: 0 auto;
        font-size: 13px;
        color: #D7655A;
    }
    /*公共分割线*/
    .ruleOne{
        width: 100%;
        border: 0.5px solid #e6e6e6;
        /*margin: 10px auto;*/
    }
    /*公共竖线的div*/
    .rule{
        font-size: 16px;
        margin-right: 10px;
        color: #e6e6e6;
        height: 22px;
    }
    /*   公共 上传图片*/
    .upLoadImg{
        margin: 15px 15px 0px;
    }
    .upLoadVedio{
margin: 15px 15px 0px;
visibility:hidden;
    }
    .deleteVideo{
        text-align: left;
        font-size: 12px;
        color: #D7655A;

    }
    .upLoadText{
        font-size: 12px;
        color: #D7655A;
        text-align: right;
        width: 95%;
    }
    /*公共的dot*/
    .grayDot{
        border: 3px solid #999999;
        border-radius: 50%;
        margin-right: 10px;
        /*margin-left: 15px;*/
    }
    .redDot{
        border: 3px solid #D7655A;
        border-radius: 50%;
        margin-right: 10px;
    }
    /*placeholder默认字体色*/
    input::placeholder{
        color: #e6e6e6;
    }
    /*/*placeholder默认字体色*/
    textarea::placeholder{
        color: #e6e6e6;
    }

    .downImage{
        width: 12px;
        height: 12px;
        margin-right: 10px;
    }
    .upImage{
        width: 12px;
        height: 12px;
        margin-right: 10px;
        transform: rotateX(180deg);
    }
    .cashierTextg11{
        font-size: 15px;
        font-weight: bold;
        margin-right: 10px;
        /*width: 350px;*/
        white-space: nowrap;
        border: none;
        height: 20px;
    }
    /*交割信息表*/
    .waterMeterDeliveryInfo{
        width: 92%;
        margin: 5px auto 30px;
        background-color: white;
        border-radius: 8px;
        /*联合收费处和水表底数DIV*/
        padding-bottom: 10px;
        .cashier{
            display: flex;
            align-items: center;
            height: 50px;
            margin: 0 15px;
            .cashierText{
                font-size: 15px;
                font-weight: bold;
                margin-right: 10px;
                /*width: 350px;*/
                white-space: nowrap;
            }
            .cashierTextg{
                font-size: 15px;
                font-weight: bold;
                margin-right: 10px;
                /*width: 350px;*/
                white-space: nowrap;
                border: none;
                /*margin-left: -4px;*/
                height: 20px;
            }
            .inputNum{
                font-size: 15px;
                /*color: #999999;*/
                background-color: white;
                /*color: white;*/
                border: none;
                height: 30px;
            }
        }
        .upLoadText{
            margin-bottom: 10px;
        }
        .textareaRemarks{
            width: 82%;
            height: 50px;
            margin: 15px auto;
            border-radius: 8px;
            display: block;
            background-color: #f7f7f7;
            border: none;
            font-size: 13px;
            color: #999;
            padding: 15px 15px;
            resize: none;
        }
    }

    .waterMeterDeliveryInfoVideo{
        width: 92%;
        position:relative;
        margin: 5px auto 30px;
        background-color: white;
        border-radius: 8px;
        /*联合收费处和水表底数DIV*/
        padding-bottom: 10px;
        .cashier{
            display: flex;
            align-items: center;
            height: 50px;
            margin: 0 15px;
            .cashierText{
                font-size: 15px;
                font-weight: bold;
                margin-right: 10px;
                /*width: 350px;*/
                white-space: nowrap;
            }
            .cashierTextg{
                font-size: 15px;
                font-weight: bold;
                margin-right: 10px;
                /*width: 350px;*/
                white-space: nowrap;
                border: none;
                /*margin-left: -4px;*/
                height: 20px;
            }
            .inputNum{
                font-size: 15px;
                /*color: #999999;*/
                background-color: white;
                /*color: white;*/
                border: none;
                height: 30px;
            }

        }
        /*    备注文本域*/
        .textareaRemarks{
            width: 82%;
            height: 50px;
            margin: 15px auto;
            border-radius: 8px;
            display: block;
            background-color: #f7f7f7;
            border: none;
            font-size: 13px;
            color: #999;
            padding: 15px 15px;
            resize: none;
        }
    }
    .van-loading{
        position:absolute;
        top:25px;
        left:25px;
    }
    .upLoadImg-vedioHide{
         visibility:hidden;
         display:inline !important;
         margin:  15px 15px 0px;
    }
    /*    房屋配置信息表*/
    .houseConfiguration {
        width: 92%;
        display: flex;
        margin: 5px auto 20px;
        /*取消按钮*/

        .cancelBtn {
            width: 22px;
            height: 20px;
            border-radius: 50%;
            background-color: #D7655A;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 13px;
            .cancelImg {
                height: 2px;
                width: 12px;
                background-color: white;
            }
        }
        /*    配置详情*/
        .houseDetail{
            width: 100%;
            background-color: white;
            margin-left: 5px;
            border-radius: 8px;
            /*配置名称*/
            .configuration{
                display: flex;
                align-items: center;
                height: 50px;
                margin: 0 15px;
                /*名称的DIV              数量的DIV*/
                .configurationName,.configurationCount{
                    font-size: 15px;
                    font-weight: bold;
                    margin-right: 10px;
                    white-space: nowrap;
                }
                /*select菜单*/
                .configurationSelect{
                    background-color: white;
                    width: 75%;
                    border: none;
                    height: 35px;
                    font-size: 15px;
                    text-align: center;
                    line-height: 35px;
                }
                /*点击用的加号*/
                .changeCount{
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    background-color: #eee;
                    color: #ddd;
                    line-height: 30px;
                    border-radius: 5px;
                }
                /*数量后面的占位DIV*/
                .placeholderDiv{
                    width:115px ;
                    input {
                        width: 90px;
                        height: 32.5px;
                        /*line-height: 40px;*/
                        font-size: 15px;
                        border: none;
                    }
                }
                /*数量值的DIV*/
                .count{
                    text-align: center;
                    width: 20px;
                    color: #D7655A;
                    font-size: 15px;
                    margin: 0 10px;
                    height: 30px;
                    line-height: 30px;
                    border: none;
                    background-color: white;
                }
            }
            /*    备注文本域*/
            .textareaRemarks{
                width: 82%;
                height: 50px;
                margin: 15px auto;
                border-radius: 8px;
                display: block;
                background-color: #f7f7f7;
                border: none;
                font-size: 13px;
                color: #999;
                padding: 15px 15px;
                resize: none;
            }
            /*房屋配置里的   请务必上传*/
            .upLoadText{
                margin-bottom: 10px;
            }
        }
    }

    /* 特殊项目*/
    .specialProject {
        width: 100%;
        //display: flex;
        //margin: 0.13rem auto 0.8rem;
        .houseDetail{
            width: 92%;
            background-color: white;
            margin: 0.13rem auto 0.8rem;
            border-radius: 8px;
            .project{
                display: flex;
                align-items: center;
                height: 50px;
                margin: 0 15px;
                .projectStatus{
                    font-size: 0.4rem;
                    font-weight: bold;
                    margin-right: 0.3rem;
                    white-space: nowrap;
                }
                .projectStatusSelect{
                    background-color: white;
                    width: 75%;
                    border: none;
                    height: 35px;
                    font-size: 15px;
                    text-align: center;
                    line-height: 35px;
                }
                .projectName{
                    width: 80%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                /*数量值的DIV*/
                .count{
                    text-align: center;
                    color: #D7655A;
                    font-size: 0.4rem;
                    border: none;
                    background-color: white;
                }
            }
            .textareaRemarks{
                width: 82%;
                height: 1.5rem;
                margin: 0.4rem;
                border-radius: 0.21333rem;
                display: inline-block;
                background-color: #f7f7f7;
                border: none;
                font-size: 0.34667rem;
                color: #999;
                padding: 0.4rem 0.4rem;
                resize: none;
            }
            .upLoadText{
                margin-bottom: 10px;
            }
        }
    }

    .addPropertyDetail{
        width: 92%;
        margin: 0rem auto 0.25rem;
        background-color: white;
        border-radius: 0.21333rem;
        padding-bottom: 0.05rem;
        padding-top: 0.05rem;
        /*    备注文本域*/
        .textareaRemarks{
            width: 82%;
            height: 50px;
            margin: 15px auto;
            border-radius: 8px;
            display: block;
            background-color: #f7f7f7;
            border: none;
            font-size: 13px;
            color: #999;
            padding: 15px 15px;
            resize: none;
        }
    }


    /*新增条目*/
    .newAdd{
        width: 25%;
        margin-left: 250px;
        margin-bottom: 20px;
        color: #D7655A;
        font-size: 13px;
        text-align: right;
    }
    /*最后保存的按钮*/
    .graySaveBtn,.redsaveBtn{
        width: 92%;
        height: 50px;
        display:block;
        margin: 20px auto 60px;
        border-radius: 8px;
        border: none;
        font-size: 14px;
        color: white;
    }
    /*按钮默认灰色*/
    .graySaveBtn{
        background-color:#999;
    }
    /*按钮满足条件变成蓝色*/
    .redsaveBtn{
        background-image: linear-gradient(to right,#FFC274,#FF5D3B);
    }
    .remove_video{

        //font-size:14px;

    }
</style>
